let ZIDXFormOffset=0;
class ZIDXFormHelper {
    data:any={};
    extraData:any={};
    formId:string="";
    getFormField(fieldName:string, defaultValue:any):any{
        console.log("data in formhelper", this.data);
        if(typeof this.data[fieldName]!="undefined") {
            return this.data[fieldName];
        }
        if(typeof this.extraData[fieldName]!="undefined") {
            return this.extraData[fieldName];
        }
        return defaultValue;
    }
    nextFormOffset(){
        ZIDXFormOffset++;
        return ZIDXFormOffset;
    }
    getForm(formConfig:ZIDXFormConfig, data:any, extraData:any) {
        this.data=data;
        this.extraData=extraData;
        let formOffset = this.nextFormOffset();
        let sb = [];
        let ms = new Date().getTime();
        this.formId = "zidxForm" + formOffset + "_" + ms;
        sb.push("<div id=\"zidxformStatusMessage" + this.formId + "\" class=\"zidxFloat\"></div>");
        sb.push("<p>* denotes a required field.</p>" +
        "<form id=\"" + this.formId + "\" class=\"zidxForm\" action=\"" + formConfig.action + "\" method=\"post\" enctype=\"multipart/form-data\" ");
        if (formConfig.enableRecaptcha) {
            sb.push(" data-recaptcha-enabled=\"1\" ");
        }
        sb.push(">");
        if(formConfig.redirectOnSaveURL!=""){
            formConfig.cancelURL=formConfig.redirectOnSaveURL;
            sb.push("<input type=\"hidden\" name=\"redirectOnSaveURL\"  autocomplete=\"off\" id=\"redirectOnSaveURL" + this.formId + "\" value=\""+window.zidxBase.htmlEditFormat(formConfig.redirectOnSaveURL.replace("&ajaxLoad=","&ztv="))+"\">");
        }
        if (formConfig.enableRecaptcha) {
            sb.push("<input type=\"hidden\" name=\"recaptchaToken\"  autocomplete=\"off\" id=\"recaptchaToken" + this.formId + "\" value=\"\">");
        }
        sb.push("<input type=\"hidden\" name=\"fileUploadingField\"  autocomplete=\"off\" id=\"fileUploadingField" + this.formId + "\" value=\"0\">");
        for(let i=0;i<formConfig.formFields.length;i++){
            let field=formConfig.formFields[i];
        
            let value="";
            let required="";
            let requiredSymbol="";
            if(field.required){
                required=" required ";
                requiredSymbol="*";
            }
            let multiple = field.multiple ? " multiple " : "";
        
            value = this.getFormField(field.name, "");
            if (this.getFormField("debug", "").length > 0) {
                if (field.type == ZIDXFormFieldType.EMAIL) {
                    value = "skyflare@gmail.com";
                } else if (field.type == ZIDXFormFieldType.URL) {
                    value = "http://www.cnbc.com/";
                } else {
                    value = "Text";
                }
            }
            if (field.type == ZIDXFormFieldType.HIDDEN) {
                sb.push("<input type=\"hidden\" name=\"" + field.name + "\" id=\"" + field.name + "\" value=\"" + window.zidxBase.htmlEditFormat(value) + "\" " + required + ">");
                continue;
            }
            sb.push("<div class=\"zidxFloat zidx-mb-10\">");
            if (field.type != ZIDXFormFieldType.SUBMIT && field.type != ZIDXFormFieldType.SEPARATOR) {
                sb.push("<div class=\"zidxLabel\">");
                if (field.type != ZIDXFormFieldType.RADIO) {
                    sb.push("<label for=\""+field.name+"\">");
                }
                sb.push(field.label);
                if (field.type != ZIDXFormFieldType.RADIO) {
                    sb.push("</label>");
                }
                sb.push(" " + requiredSymbol + "</div>");
                if (field.help.length > 0) {
                    sb.push("<div class=\"zidxFieldHelp\">"+field.help+"</div>");
                }
            }
            sb.push("<div class=\"zidxField\">");
            if (field.type == ZIDXFormFieldType.TEXT) {
                sb.push("<input type=\"text\" name=\""+field.name+"\" id=\""+field.name+"\" placeholder=\""+field.placeholder+"\" value=\""+window.zidxBase.htmlEditFormat(value)+"\" class=\"zidxDefaultInput\" "+required+">");
        
            } else if (field.type == ZIDXFormFieldType.URL) {
                sb.push("<input type=\"url\" name=\""+field.name+"\" id=\""+field.name+"\" placeholder=\""+field.placeholder+"\" value=\""+window.zidxBase.htmlEditFormat(value)+"\" class=\"zidxDefaultInput\" "+required+">");
        
            } else if (field.type == ZIDXFormFieldType.CUSTOM) {
                sb.push(field.custom);
            } else if (field.type == ZIDXFormFieldType.HTML) {
                if (field instanceof ZIDXHTMLFormField) {
                    sb.push("<textarea name=\"" + field.name + "\" id=\"" + field.name + "\" class=\"zidxTinyMCEEditor\" width=\"" + field.width + "\" height=\"" + field.height + "\" >" + value + "</textarea>");
                }else {
                    sb.push("<textarea name=\"" + field.name + "\" id=\"" + field.name + "\" class=\"zidxTinyMCEEditor\">" + value + "</textarea>");
                }
            } else if (field.type == ZIDXFormFieldType.EMAIL) {
        
                sb.push("<input type=\"email\" name=\""+field.name+"\" id=\""+field.name+"\" placeholder=\""+field.placeholder+"\" value=\""+window.zidxBase.htmlEditFormat(value)+"\" class=\"zidxDefaultInput\" "+required+multiple+">");
        
            } else if (field.type == ZIDXFormFieldType.SEPARATOR) {
                sb.push("<div class=\"zidxFloat z-pt-20\">"+field.html+"</div>");
        
            } else if (field.type == ZIDXFormFieldType.FILE) {
                if (field instanceof ZIDXFileFormField) {
                    if (field.downloadPath == null) {
                        throw("field.downloadPath must be defined");
                    }
                    if (field.uploadPath == null) {
                        throw("field.uploadPath must be defined");
                    }
                    if (value!="") {
                        sb.push("<p>"+value+" &nbsp;&nbsp; <a href=\""+field.downloadPath+value+"\" target=\"_blank\" class=\"zidxManagerSearchButton\">View File</a></p>");
                        sb.push("<p><input type=\"checkbox\" name=\""+field.name+"_delete\" id=\""+field.name+"_delete\" value=\"1\" style=\"background:none; border:none;height:15px; \" /> <label for=\""+field.name+"_delete\">Delete file</label></p>");
                    }
                } else {
                    throw("You must use new FileFormField() when the field type is ZIDXFormFieldType.FILE");
                }
                sb.push("<input type=\"file\" name=\""+field.name+"\" id=\""+field.name+"\" placeholder=\""+field.placeholder+"\" value=\"\" class=\"zidxDefaultInput\" "+required)
                if (field.multiple) {
                    sb.push(" multiple");
                }
                sb.push(">");
            } else if (field.type == ZIDXFormFieldType.IMAGE) {
                if (field instanceof ZIDXImageFormField) {
                    if (field.sizes == null) {
                        throw("field.sizes must be defined");
                    }
                    if (field.downloadPath == null) {
                        throw("field.downloadPath must be defined");
                    }
                    if (field.uploadPath == null) {
                        throw("field.uploadPath must be defined");
                    }
                    if (value!="") {
                        sb.push("<p><img src=\""+field.downloadPath+window.zidxBase.htmlEditFormat(value)+"\" style=\"max-width:250px;\" alt=\"Image\"></p>");
                        sb.push("<p><input type=\"checkbox\" name=\"" + field.name + "_delete\" id=\"" + field.name + "_delete\" value=\"1\" style=\"background:none; border:none;height:15px; \" /> <label for=\"" + field.name + "_delete\">Delete file</label><br /></p>");
                    }
                } else {
                    throw("You must use new ImageFormField() when the field type is ZIDXFormFieldType.IMAGE");
                }
                sb.push('<div data-field="'+field.name+'" class="zidxPreviewUploadImageGallery"></div>');
                sb.push("<input type=\"file\" name=\""+field.name+"\" id=\""+field.name+"\" placeholder=\""+field.placeholder+"\" value=\"\" accept=\"image/*\" class=\"zidxDefaultInput zidxPreviewUploadImageInput\" "+required);
                if (field.multiple) {
                    sb.push(" multiple");
                }
                sb.push(">");
                sb.push('<progress id="progressBar1"></progress>')
        //            } else if (field.type == ZIDXFormFieldType.PHOTO_LIBRARY) {
                //sb.push("TODO: PHOTO LIBRARY");
        //                ts=structnew();
        //                ts.name="listing_image_library_id";
        //                ts.value=value;
        //                imagecom=createObject("component", response.rootCFCPath&"mvc.front.library.controller.image-library");
        //                imagecom.getLibraryForm(ts);
        
            } else if (field.type == ZIDXFormFieldType.PASSWORD) {
                sb.push("<input type=\"password\" name=\""+field.name+"\" id=\""+field.name+"\" placeholder=\""+field.placeholder+"\" value=\""+window.zidxBase.htmlEditFormat(value)+"\" class=\"zidxDefaultInput\" "+required+">");
        
            } else if (field.type == ZIDXFormFieldType.DATE) {
                sb.push("<input type=\"date\" name=\""+field.name+"\" id=\""+field.name+"\" placeholder=\""+field.placeholder+"\" value=\""+window.zidxBase.htmlEditFormat(value)+"\" "+required+">");
            } else if (field.type == ZIDXFormFieldType.RADIO) {
                let valueCount = field.values.length;
                for (let n = 0; n < valueCount; n++) {
                    let t = field.values[n];
                    if (valueCount != 2) {
                        sb.push("<p>");
                    }
                    sb.push("<input type=\"radio\" name=\""+field.name+"\" id=\""+field.name+n+"\" value=\""+t.value+"\" ");
                    if (this.getFormField("debug", "").length > 0 && n == 0) {
                        sb.push(" checked ");
                    }
                    if (value==t.value) {
                        sb.push(" checked ");
                    }
                    if(valueCount== 2 && n!=0){
                        sb.push(" style=\"margin-left:15px;\"");
                    }
                    sb.push(" "+required+"> <label for=\""+field.name+n+"\">"+t.label+"</label>");
                    if (valueCount != 2) {
                        sb.push("</p>");
                    }
                }
            } else if (field.type == ZIDXFormFieldType.BOOLEAN) {
                // loop array
                sb.push("<input type=\"radio\" name=\""+field.name+"\" id=\""+field.name+"1\" value=\"1\" "+required+" ");
                if (this.getFormField("debug", "").length > 0 && value=="1") {
                    sb.push(" checked ");
                }
                if (value=="1") {
                    sb.push(" checked ");
                }
                sb.push("> <label for=\""+field.name+"1\">Yes</label> &nbsp;");
                sb.push("<input type=\"radio\" name=\""+field.name+"\" id=\""+field.name+"0\" value=\"0\" "+required+" ");
                if (value=="0") {
                    sb.push(" checked ");
                }
                sb.push("> <label for=\""+field.name+"0\">No</label>");
        
            } else if (field.type == ZIDXFormFieldType.CHECKBOX) {
                // loop array
                let valueCount = field.values.length;
                if (valueCount == 0) {
                    sb.push("<input type=\"checkbox\" name=\""+field.name+"\" id=\""+field.name+"\" value=\"1\" "+required+" ");
                    if (this.getFormField("debug", "").length > 0) {
                        sb.push(" checked ");
                    }
                    if (value=="1") {
                        sb.push(" checked ");
                    }
                    sb.push("> <label for=\""+field.name+"\">"+field.label+"</label>"); // Scott thinks </div> is needed here
                } else {
                    let arrSelected = value.split(",");
                    for (let n = 0; n < valueCount; n++) {
                        let t = field.values[n];
                        sb.push("<div style=\"width:50%; float:left; min-width:280px; padding-bottom:5px;\"><input type=\"checkbox\" name=\""+field.name+"\" id=\""+field.name+n+"\" value=\""+window.zidxBase.htmlEditFormat(t.value)+"\" "+required+" ");
                        if (this.getFormField("debug", "").length > 0 && n == 1) {
                            sb.push(" checked ");
                        }
                        for (let g=0;g<arrSelected.length;g++) {
                            let selectedValue=arrSelected[g];
                            if (selectedValue!="" && selectedValue==t.value) {
                                sb.push(" checked ");
                                break;
                            }
                        }
                        sb.push("> <label for=\""+field.name+n+"\">"+t.label+"</label></div>");
                    }
                }
        
            } else if (field.type == ZIDXFormFieldType.TEXTAREA) {
                sb.push("<textarea name=\""+field.name+"\" id=\""+field.name+"\" class=\"zidxDefaultInput\" style=\"height:100px;\" "+required+">"+window.zidxBase.htmlEditFormat(value)+"</textarea>");
            } else if (field.type == ZIDXFormFieldType.SELECT) {
                // loop array
                sb.push("<select name=\""+field.name+"\" id=\""+field.name+"\" class=\"zidxDefaultInput\" size=\"1\" "+required+">");
                let valueCount = field.values.length;
                for (let n = 0; n < valueCount; n++) {
                    let t = field.values[n];
                    sb.push("<option value=\""+t.value+"\" ");
                    if (this.getFormField("debug", "").length > 0 && n == 1) {
                        sb.push(" selected ");
                    }
                    if (value==t.value) {
                        sb.push(" selected ");
                    }
                    sb.push(">"+t.label+"</option>");
                }
                sb.push("</select>");
            } else if (field.type == ZIDXFormFieldType.SUBMIT) {
                sb.push("<input type=\"submit\" name=\""+field.name+"\" id=\""+field.name+"\" value=\""+field.label+"\">");
            } else if (field.type == ZIDXFormFieldType.BUTTON) {
                sb.push("<input type=\"button\" name=\""+field.name+"\" id=\""+field.name+"\" value=\""+field.label+"\">");
            } else {
                throw(field.type + " is not a supported field type.");
            }
            sb.push("</div>");
            if (field.helpSuffix.length > 0) {
                sb.push("<div class=\"zidxFieldHelp\">"+field.helpSuffix+"</div>");
            }
            sb.push("</div>");
        }
        
        if (!formConfig.disableButtons) {
            sb.push("<div class=\"zidxFloat\">" +
                "<div class=\"zidxField\">" +
                "<input type=\"submit\" name=\"submit1\" value=\"");
            sb.push(formConfig.submitLabel);
            sb.push("\"> ");
            if (formConfig.cancelURL.length > 0) {
                sb.push("<input type=\"button\" name=\"cancel1\" value=\"Cancel\" onclick=\"ZIDX.loadPushStateURL('"+formConfig.cancelURL+"', false);\">");
            }
            sb.push("</div></div>");
            if (formConfig.enableRecaptcha && this.isRecaptchaEnabled()) {
                sb.push("<div class=\"zidxFloat zidxCaptchaDisclaimer\">" +
                    "<div class=\"zidxField\">"+
                    "By using this website, you signify your agreement to the <a href=\"/terms-of-use/\" target=\"_blank\">terms of use</a> and <a href=\"/privacy-policy\" target=\"_blank\">privacy policy</a>"+
                    "</div></div>");
            }
        }
        sb.push("</form>");
        return sb.join("");
    }
    setupEvents(){
        let self=this;
        ZIDX.initTinyMCEEditors();
        let progressBar = (<HTMLProgressElement>document.getElementById("progressBar1")!);
        ZIDX.$('.zidxPreviewUploadImageInput').on('change', function(this:HTMLInputElement) {
            self.imagesPreview(this, '.zidxPreviewUploadImageGallery', progressBar);
        });
        // console.log('loading ajax progress once');
        // let client = new XMLHttpRequest();
        // client.open("GET", "/images/videotest.mp4")
        // client.onprogress = function(pe) {
        //     if(pe.lengthComputable) {
        //         progressBar.max = pe.total;
        //         progressBar.value = pe.loaded;
        //         console.log('progress', pe.total, pe.loaded);
        //     }else{
        //         console.log('no compute');
        //     }
        // }
        // client.onloadend = function(pe) {
        //     progressBar.value = pe.loaded;
        //     console.log('loaded');
        // }
        // client.send();
        // console.log('try ajax download');
    }
    imagesPreview(input:HTMLInputElement, placeToInsertImagePreview:any, progressElement:HTMLProgressElement) {
        ZIDX.$(placeToInsertImagePreview).html("");
        let uploads=[];
        if (input.files && input.files.length>0) {
            let filesAmount = input.files.length;
            for (let i = 0; i < filesAmount; i++) {
                let reader = new FileReader();
                reader.onload = function(event) {
                    if(event.target && typeof event.target.result=="string") {
                        var img=document.createElement("img");
                        img.src=event.target.result;
                        ZIDX.$(placeToInsertImagePreview).append(img);
                    }
                }
                reader.readAsDataURL(input.files[i]);
            }
            let previewElement = (<HTMLDivElement>document.getElementById(input.id+"_preview")!);
            let fieldElement = (<HTMLInputElement>document.getElementById(input.id+"")!);
            let urlElement = (<HTMLInputElement>document.getElementById(input.id+"_url")!);
            let userTable = (<HTMLInputElement>document.getElementById(input.id+"_usertable")!).value;
            let imageSizes="";
            if(document.getElementById(input.id+"_imagesizes")!=null) {
                imageSizes = (<HTMLInputElement>document.getElementById(input.id + "_imagesizes")!).value;
            }
            let isUserTable=false;
            if(userTable=="1"){
                isUserTable=true;
            }
            // temporarily disabled
            console.log("form element :", document.getElementById(this.formId));
            let uploadProgress=new ZIDXFileUploadProgress(<HTMLFormElement>document.getElementById(this.formId), previewElement, fieldElement, input, true, isUserTable, imageSizes, urlElement, progressElement);
            uploadProgress.doUpload();
        }
    }

    
    removeHTML(text:string):string {
        text = text.replace("<(style|script|embed|base|input|textarea|button|object|iframe|form).*?</1>", " ");
        text = text.replace("(</|<)[^>]*>", " ");
        text = text.replace("&nbsp;", " ");
        return text;
    }
    
    cleanHtml(html:string):string {
        let badTagList = "style|script|embed|base|input|textarea|button|object|iframe|form";
        return html.replace("<(" + badTagList + ").*?</\\1>", " ")
            .replace("(</|<)(" + badTagList + ")[^>]*>", " ")
            .replace("&lt;(" + badTagList + ").*?&lt;/\\1&gt;", " ")
            .replace("(&lt;/|&lt;)(" + badTagList + ")[^(&gt;)]*&gt;", " ")
            .replace("j(\s*)a(\s*)v(\s*)a(\s*)s(\s*)c(\s*)r(\s*)i(\s*)p(\s*)t(\s*):", "javascript - ")
            .replace("on([a-zA-Z\s])*=", "\0&##61;")
            ;
    }

    isRecaptchaEnabled():boolean {
        if (ZIDX.options.recaptchaSiteKey.length > 0) {
            return true;
        }
        return false;
    }
}
